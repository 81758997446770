<template>
  <b-overlay :show="!ready" variant="dark" opacity="0.85" blur="2px" rounded="sm">
    <section class="invoice-preview-wrapper" v-if="orderDetail.ready">
      <b-row class="invoice-preview d-flex justify-content-center">
        <!-- Col: Left (Invoice Container) -->
        <b-col cols="12" md="10" xl="9" v-if="ready">
          <b-card no-body class="invoice-preview-card">
            <!-- Header -->
            <b-card-body class="invoice-padding pb-0">
              <div
                class="
                  d-flex
                  justify-content-between
                  flex-md-row flex-column
                  invoice-spacing
                  mt-0
                "
              >
                <!-- Header: Left Content -->
                <taker-address />
                <!-- Header: Right Content -->
                <order-info />
              </div>
            </b-card-body>

            <!-- Invoice Client & Payment Details -->
            <b-card-body class="invoice-padding pt-0">
              <b-row class="invoice-spacing">
                <!-- Col: Invoice To -->
                <customer-address />
                <!-- Col: Created By -->
                <order-created-by />
              </b-row>
            </b-card-body>

            <order-products />

            <div v-if="orderDetail.details.payment_type === 1">
              <!-- Invoice Client & Payment Details -->
              <cc-giver-address />
              <!-- Ödeme Detay -->
              <cc-detail />
              <!-- Invoice Description: Total -->
              <cc-amount />
            </div>
          </b-card>

          <div class="d-flex justify-content-end print-button-div">
            <b-button variant="warning" class="mb-75 mr-1" @click="goEdit()" v-if="$can('manage', '/Order/Edit') && orderDetail.details.order_state === 1">
              {{ $t('order.go_edit_button_text') }}
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="primary" class="mb-75" @click="printInvoice">
              {{ $t('transaction.print') }}
            </b-button>
          </div>
        </b-col>
      </b-row>
    </section>
    <b-alert variant="danger" :show="ready" v-else>
      <h4 class="alert-heading d-flex justify-content-between">
        <div>
          {{ $t('order.detail') }}
        </div>
        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="none" size="sm" @click="getOrderDetail()">
          <i class="fal fa-sync-alt text-danger" />
        </b-button>
      </h4>
      <div class="alert-body">
        <span>{{ $t('notifications.get_order_detail_not_found') }}</span>
      </div>
    </b-alert>
  </b-overlay>
</template>

<script>
import { BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle, BOverlay } from 'bootstrap-vue'
import TakerAddress from './TakerAddress.vue'
import OrderInfo from './Info.vue'
import OrderProducts from './Products.vue'
import CustomerAddress from './CustomerAddress.vue'
import OrderCreatedBy from './CreatedBy.vue'
import CcGiverAddress from './CCGiverAddress.vue'
import CcDetail from './CCDetail.vue'
import CcAmount from './CCAmount.vue'

import Ripple from 'vue-ripple-directive'
import { GET_ORDER_DETAIL } from '@/store/services/order-service'
import { mapGetters } from 'vuex'

export default {
  name: 'order-detail',
  directives: {
    Ripple,
    'b-toggle': VBToggle
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    VBToggle,
    BOverlay,

    TakerAddress,
    OrderInfo,
    OrderProducts,
    CustomerAddress,
    OrderCreatedBy,
    CcGiverAddress,
    CcDetail,
    CcAmount
  },
  data() {
    return {
      orderMId: this.$route.params.id,
      ready: false
    }
  },
  created() {
    this.getOrderDetail()
  },
  computed: {
    ...mapGetters(['currency', 'getLocale', 'orderDetail'])
  },
  methods: {
    getOrderDetail() {
      this.ready = false
      this.$store.dispatch(GET_ORDER_DETAIL, this.orderMId).then(() => {
        this.ready = true
      })
    },
    printInvoice() {
      window.print()
    },
    goEdit() {
      this.$router.push({
        name: '/Order/Edit',
        params: {
          id: this.orderMId
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-invoice.scss';
.invoice-total-wrapper {
  margin-right: 100px;
}
.invoice-total-item {
  width: 250px;
}
.invoice-logo {
  max-width: 500px;
  font-size: 1.25rem !important;
  margin: 0 !important;
}
.invoice-date-title {
  width: auto !important;
}

.detail-table {
  table {
    thead,
    tbody {
      tr {
        td,
        th {
          padding: 0.25rem !important;
          vertical-align: middle;
        }
      }
    }
    caption {
      table {
        thead,
        tbody {
          tr {
            td,
            th {
              padding: 0.25rem 1rem !important;
              vertical-align: middle;
            }
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
.product-name {
  max-width: 250px;
}
@media print {
  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none !important;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
  .print-button-div {
    display: none !important;
  }
  .breadcrumbs-top {
    display: none;
  }
  .btn-scroll-to-top {
    display: none;
  }
  table {
    padding: 5px !important;
    margin: 0 !important;
    thead {
      padding: 5px !important;
      margin: 0 !important;
      tr {
        padding: 5px !important;
        margin: 0 !important;
        td,
        th {
          padding: 5px !important;
          margin: 0 !important;
        }
      }
    }
    tbody {
      padding: 5px !important;
      margin: 0 !important;
      tr {
        padding: 5px !important;
        margin: 0 !important;
        td,
        th {
          padding: 5px !important;
          margin: 0 !important;
        }
      }
    }
    tfoot {
      padding: 5px !important;
      margin: 0 !important;
      tr {
        padding: 5px !important;
        margin: 0 !important;
        td,
        th {
          padding: 5px !important;
          margin: 0 !important;
        }
      }
    }
  }
}
</style>
